import Vue from "vue";
import VueRouter from 'vue-router';
import Index from  '../views/index'


Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'index',
        // component: Index
        component: ()=>import( '@/views/login/index.vue')
    },
    {
        path: '/example',
        name: 'example',
        component: ()=>import( '@/views/example/index.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: ()=>import( '@/views/login/index.vue')
    },
    {
        path: '/home',
        name: 'home',
        component: ()=>import( '@/views/home/index.vue')
    },
]

const router = new VueRouter({
    routes
})

export default router