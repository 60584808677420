<template>
  <div>
    <img src="@/img/2.png" style="width: 100vw;height: 100vh;" alt="">
  </div>
</template>

<script>
export default {
  data(){
    return {

    }
  }
}
</script>

<style>

</style>